"use client";

import React, { useState, Fragment } from 'react'
import "../style.scss"
import Link from 'next/link';
import { ImageFallBack } from './image-fallback';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider } from '@/components/ui/tooltip';
import { Icon } from '@iconify/react';
// import verifiedIcon from "@/public/check.png";
import { rootUrl, copyToClipboardByValue } from '@/utils';
// import { IImage } from '@/composables/image.type';
import { IPaymentLink } from '@/composables/paymentlink.type';
import { handleResizeImageUrl } from '@/utils';
import { IUser } from '@/composables/user.type';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import Image from 'next/image';

type BannerCatalog = {
  images: IPaymentLink[];
  bannerCount: number;
  setBannerCount: React.Dispatch<React.SetStateAction<number>>;
  user: IUser;
  subdomainLink: string;
  username: string;
}

const BannerCatalog = ({ images, bannerCount, setBannerCount, user, subdomainLink,username }: BannerCatalog) => {
  const { t } = useTranslation();
  const [buttonCopy, setButtonCopy] = useState(false);

  return (
    <>
      <div className="w-full">
        <div className="bg-banner w-full relative top-0">
          {[...Array(Math.ceil(30 / (images.length - bannerCount)))].map((e, i) => (
            <Fragment key={i}>
              {images?.map(({ multipleImage, coverImage }, index) => {
                return (
                  <ImageFallBack
                    alt="banner"
                    key={index}
                    idx={i}
                    setBannerCount={setBannerCount}
                    bannerCount={bannerCount}
                    className="banner-image-grid"
                    src={coverImage ? coverImage.url : multipleImage[0].url}
                  />
                );
              })}
            </Fragment>
          ))}
          <div className="banner flex justify-center items-center">
            <div className={cn(
              "container lg:!mt-14 xl:!mt-28 container-product-catalog",
            )}>
              <div className="catalog-blur mt-[10px] flex items-center float-right">
                <Popover>
                  <PopoverTrigger asChild>
                    <button className='px-[15px] py-[10px]'>
                      <Icon icon="mage:dots" className='text-[22px] text-white' />
                    </button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-1 bg-white flex flex-col text-gray-500">
                    <Link href={`/portal`}>
                      <Button variant="link" className='text-[12px]'>
                        {t('word.enterPortalCustomer')}
                      </Button>
                    </Link>
                  </PopoverContent>
                </Popover>
              </div>
              <img
                alt="logo"
                src={handleResizeImageUrl(user?.account?.logo?.url, 300) || process.env.NEXT_PUBLIC_DEFAULT_USER_AVATAR}
                className="catalog-logo rounded-full mb-[5px] mt-[50px]"
              />
              <div className="flex items-center mt-[20px]">
                <Link
                  href={process.env.NODE_ENV === 'development' ? `/${username}` : '/'}
                  className="my-auto"
                >
                  <h1 className="mb-0 text-[39px] text-white font-[600]" style={{ color: "white" }}>
                    {user?.account?.name}
                  </h1>
                </Link>
                <button
                  className="border-0 flex icon-copy"
                  onClick={() => {
                    copyToClipboardByValue(rootUrl(subdomainLink).split('//')[1]);
                    setButtonCopy(true);
                    setTimeout(() => {
                      setButtonCopy(false);
                    }, 3000);
                  }}
                >
                  <Icon icon="bi:copy" className="ml-[10px] my-auto text-xl text-white" />
                </button>

                <TooltipProvider>
                  <Tooltip open={buttonCopy}>
                    <TooltipContent>
                      Copied!
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                
              </div>
              <div className="catalog-blur px-[15px] py-[10px] mt-[10px] flex gap-1.5 items-center">
                <p className="catalog-name my-0 font-[400] text-[13px]">
                  {rootUrl(subdomainLink).split('//')[1]}
                </p>
                {user?.account?.isVerified && (
                  <Image src="/check.png" alt="Verified account" width="17" height="17" className='h-[17px]'/>
                )}
              </div>
              {user?.account?.profile ? (
                <div className="catalog-blur px-[15px] py-[15px] mt-[10px] flex items-center">
                  <p className="catalog-name my-0 profile-overflow">
                    <span className="font-profile font-[400]">{user?.account?.profile}</span>
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        
      </div>
    </>
  )
}

export default BannerCatalog;
