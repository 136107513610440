"use client";

import React from 'react'
import "../style.scss";
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { IPaymentLink } from '@/composables/paymentlink.type';
// import { formatRp } from '@/lib/hybridUtils';
import { findCheapestTierPeriodPrice, formatRp, getCoverImage } from '@/utils';
import { productPageDetail } from '@/utils';

type ProductOptionType = {
  value: string;
  label: string;
  isDisabled: boolean;
  color: string;
  tailwindcolor: string;
}

const ProductCard = ({ id, name, amount, coverImage, multipleImage, type, productOption, paymeLink, link, subType, membershipTier }: Partial<IPaymentLink & { productOption: ProductOptionType, paymeLink: string }>) => {
  const router = useRouter();
  const { t } = useTranslation();
  const goToPayLink = (link: string, type: string, subType: string | undefined) => {
    let payLink = `/pl/${link}`;

    if (type === 'physical_product') {
      payLink = `/checkout/${link}`;
    }

    if (type === 'membership') {
      payLink = `/m/${link}`;
    }

    if (subType) {
      payLink = `/donate/${link}`;
    }

    return router.push(payLink);
  };
  let imageSrc = getCoverImage(coverImage, multipleImage, "sm");

  let cheapestMembershipTierPeriod = 0;
  if (type === 'membership')
    cheapestMembershipTierPeriod = findCheapestTierPeriodPrice(
      membershipTier,
    )?.lowestAmountPeriod;

  return (
    <Link
      href={productPageDetail(
        paymeLink,
        link,
        type,
        subType,
      )}
      className='lg:w-[31%] sm:w-[47%] w-full'
    >
      <Card className='w-full bg-white relative'>
        <Badge
          className={`absolute top-4 left-4 text-white text-[10px] font-open-sans font-[600]`}
          style={{
            backgroundColor: productOption?.color,
          }}
        >
          {productOption?.label}
        </Badge>
        <img
          // src="/digital-product-placeholder.webp"
          loading='eager'
          fetchPriority='high'
          src={imageSrc || "/digital-product-placeholder.webp"}
          alt=""
          className='rounded object-cover rounded-b-none h-[240px] w-full'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/digital-product-placeholder.webp';
            imageSrc = '/digital-product-placeholder.webp';
          }}
          // height="240px"
          // width={'100%'}
        />
        <CardContent className='py-[25px] px-[30px] flex flex-col'>
          <div className='text-[14px] text-[#6c757d] mb-[10px] -mt-[2px] font-[700] truncate'>{name}</div>
          <div className='flex justify-between items-center'>
            <p className='font-[700] text-[#343a40] text-[16px] m-0 leading-[24px]'>{formatRp(type === 'membership' ? cheapestMembershipTierPeriod : amount!)}</p>
            <Button
              variant={'primary'}
              className='text-[11px] rounded px-[15px] py-[9px] z-50 uppercase flex justify-center items-center leading-0 h-auto'
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                goToPayLink(link!, type!, subType)
              }}
            >{t('word.buy')}</Button>
          </div>
        </CardContent>
      </Card>
    </Link>
    
  )
}

export default ProductCard;
